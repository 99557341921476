export const errors = {
    "42026": "Item weight exceeds the maximum weight for this service.",
    "42028": "The parcel girth exceeds the maximum allowed of 140 cm",
    "44013": "This consignment cannot be found. If you've deleted the consignment in the MyPost web site you will need to reset this order and then create a new consignment.",
    "45006": "Australia Post were unable to process payment for this consignment using the stored payment method. Visit the MyPost web site and login to your account to update your payment details or resolve the issue with your payment provider.",
    "51010": "This label cannot be printed. The 48 hours to reprint the label has expired. Visit the MyPost web site if you need to cancel and receive a refund for this consignment. You can then reset this order to generate a new consignment.",
    "The product ID is not available.": "The MyPost service selected is not available. For international orders it is possible that the service has been suspended. See the Australia Post web site for more information.",
    "The input request is missing the mandatory field with the name name.  Please resubmit the request including the required fields and values.": "The 'Name' field in your 'Sender / Return Address' field on the 'Preferences' page is required by MyPost. Please update the field and try again.",
    "SERVICE_UNAVAILABLE": "The Australia Post MyPost Business service is currently unavailable.",
    "No network found for your selection from AU to": `The Australia Post service selected for this order is currently suspended. For more information see the <a target="_blank" href="https://auspost.com.au/service-updates/international-delivery-times#international">AusPost International Delivery Times Guide</a>.`,
    "The lines[0] must not be longer than 40 characters.": "The street address is too long. AusPost has a 40 character limit on each line of a street address. Try splitting the address over two lines.",
    "The weight value must be greater than 0kg and less than or equal to 20kg.": "At least one item in the order has a weight of 0kg or exceeds 20kg. Edit the weights by clicking the pencil icon in the Products section of the order or disable declaring item weights in your MyPost Business Default Settings.",
    "The input shipment request is invalid for item content weight. Please ensure total weight of all item contents is less than or equal to item weight.": "The total package weight declared is less than the aggregate individual item weights declared. Either edit the package weight, the individual item weights by clicking the pencil icon in the Products section or disable declaring item weights in your MyPost Business Default Settings."
}